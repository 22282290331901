import React from 'react';

type DetailsSubscriptionProps = Partial<{
  subscribed: boolean;
  willRenew: boolean;
  endDate: string;
}>;

const DetailsSubscription = ({ subscribed, willRenew, endDate }: DetailsSubscriptionProps) => {
  if (!subscribed) {
    return null;
  }

  if (willRenew) {
    return (
      <div>
        <p>Your membership renews on: {new Date(endDate).toDateString()}.</p>
      </div>
    );
  }

  return (
    <div>
      <p>Your membership ends on: {new Date(endDate).toDateString()}.</p>
    </div>
  );
};

export default DetailsSubscription;
