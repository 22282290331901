import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootStoreState } from '@src/store';
import {
  createSubscriptionPortalSession,
  getSubscriptionDetails,
  getSubscriptionDetailsReturnData,
} from '@src/utils/mmc-api/typedApi';

import { useQuestionBank } from '@src/hooks/user';
import DetailsSubscription from '@src/components/Profile/Form/Details/Details.subscription';
import * as styles from '../../components/Profile/Form/Details/Details.module.css';

const SubscriptionDetails = () => {
  const storeUser = useSelector<RootStoreState>((state) => state.user);
  const [subscriptionDetails, setSubscriptionDetails] =
    useState<getSubscriptionDetailsReturnData['data']>();

  useEffect(() => {
    const get_end_date = async () => {
      const response = await getSubscriptionDetails();
      setSubscriptionDetails(response?.data);
    };
    if (storeUser) {
      get_end_date();
    }
  }, [storeUser]);

  const [manageSubscriptionLoading, setManageSubscriptionLoading] = useState(false);

  const onManageSubscription = useCallback(() => {
    console.log(storeUser);
    setManageSubscriptionLoading(true);
    // @ts-ignore
    if (!manageSubscriptionLoading && storeUser?.data?.stripeId) {
      createSubscriptionPortalSession({
        //@ts-ignore
        stripe_customer_id: storeUser?.data?.stripeId,
        success_url: window.location.origin,
      })
        .then((ret) => {
          if (ret.success && ret.url) {
            window.location.href = ret.url;
          }
        })
        .finally(() => {
          setManageSubscriptionLoading(false);
        });
    }
  }, [manageSubscriptionLoading, storeUser]);

  const questionBank = useQuestionBank();

  const showManageSubscription =
    (subscriptionDetails?.isSubscribed && subscriptionDetails.subscriptions) ||
    questionBank?.subscribed;

  console.log(subscriptionDetails);

  return (
    <>
      {
        subscriptionDetails?.subscriptions?.map((sub) => {
          const scopes = [];
          if (sub.scopeMainContent) {
            scopes.push("Main Subscription")
          }
          if (sub.scopeQBContent) {
            scopes.push("Question Bank Access")
          }
          
          return (
            <div>
              <h4>{`Subscription (${scopes.join(", ")})`}</h4>
              <DetailsSubscription
                subscribed={subscriptionDetails.isSubscribed}
                endDate={sub.endDate}
                willRenew={sub.willRenew}
              />
            </div>
          )
        })
      }

      {showManageSubscription && (
        <div>
          <button className={styles.manageSubscription} onClick={onManageSubscription}>
            {manageSubscriptionLoading ? 'Loading...' : 'Manage Subscription(s)'}
          </button>
          <p className={styles.smallText}>
            Or contact <a href="mailto:francesca@mymathscloud.com">francesca@mymathscloud.com</a> if
            you have any problems.
          </p>
        </div>
      )}
    </>
  );
};

export default SubscriptionDetails;
